import React, { useState } from "react";
import './Auth.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import GoogleLogo from "../../Images/Logos/google.webp";
import { auth } from "../../Firebase/firebaseAuth";
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from "../../Firebase/firebaseAuth";

const Login = ({ setMode }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorTxt, setErrorTxt] = useState("");

    const handleEmailAuth = async (email, password) => {
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setErrorTxt("");
            })
            .catch((error) => {
                console.error("Error:", error);
                setErrorTxt("Invalid credentials.");
            });
    };

    const handleGoogleAuth = async () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then(() => {
                setErrorTxt("");
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLoginBtnClick = () => {
        handleEmailAuth(email, password);
    };

    const navigateToRegister = () => {
        setErrorTxt("");
        setMode("register");
    };

    const navigateToForgotPassword = () => {
        setErrorTxt("");
        setMode("password");
    };

    const loginEnabled = email !== "" && password !== "";

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-title">
                    Login
                </div>
                <div className="email-input">
                    <TextField
                        required
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="password-input">
                    <TextField
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <div className="error-txt">{errorTxt}</div>
                <div className="login-btn">
                    <Button
                        size="large"
                        fullWidth
                        variant="contained"
                        disabled={!loginEnabled}
                        onClick={handleLoginBtnClick}>
                        Login
                    </Button>
                </div>
                <div className="under-txt-container">
                    <div
                        className="under-txt"
                        onClick={navigateToRegister}>
                        Create Account
                    </div>
                    <div
                        className="under-txt"
                        onClick={navigateToForgotPassword}>
                        Forgot Password
                    </div>
                </div>
                <div className="or-container">
                    <Divider className="or-divider" />
                    <div className="or-text">Or</div>
                    <Divider className="or-divider" />
                </div>
                <div className="auth-container" onClick={handleGoogleAuth}>
                    <img className="auth-logo" src={GoogleLogo} />
                    <div className="auth-txt">Sign in with Google</div>
                </div>
            </div>
        </div>
    );
};

export default Login;
