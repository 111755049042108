import { TbInfoCircleFilled } from "react-icons/tb";

function IconWithTooltip({ tooltipText }) {
  return (
    <div className="relative inline-block group">
      {/* Icon */}
      <TbInfoCircleFilled className="text-2xl text-onSurfaceSecond" />

      {/* Tooltip */}
      <div className="absolute w-48 p-2 mt-2 text-sm text-center text-white transition-opacity duration-300 transform rounded-lg opacity-0 bg-neutral-600 left-1/2 top-full group-hover:opacity-100 ">
        {tooltipText}
      </div>
    </div>
  );
}

export default IconWithTooltip;
