import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { GamesInfo } from '../../Constants/Games';
import { TbArrowBadgeLeft, TbArrowBadgeRight } from "react-icons/tb";

export default function GameView( { game, player1, player2, ranked } ) {

  const [gameData, setGameData] = useState({});
  const [moves, setMoves] = useState([]);
  const [currMove, setCurrMove] = useState(0);
  const [loading, setLoading] = useState(true);

  // TODO: on leaderboard page users should be able to select two bots and then pass in those to this popup to see visualzation
  useEffect(() => {
    const getGame = async () => {
      
      const res = await fetch(process.env.REACT_APP_endpointURL + "/match", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "game": game,
          "player1": player1,
          "player2": player2,
          "ranked": ranked
        }),
      });

      const result = await res.json();
      setMoves(JSON.parse(result.moves));
      setGameData(result);
      setLoading(false);
    }

    getGame();
    
  }, [])

  if (loading) {
    return (
      <Box display="grid" justifyContent="center" padding={10}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <div className='grid justify-items-center gap-4 px-8 py-40 md:px-10'>
      <div className='grid'>
        {moves[currMove].map((row, i) => {
          return (
          <div key={i} className='flex'>
            {row.map((el, j) => {
              return (<p key={i+''+j} className="text-4xl">{GamesInfo[game].visualMap[el]}</p>)
            })}
          </div>
          )
        })
        }
      </div>
      <div className='flex flex-row gap-4 items-center'>
        <button onClick={() => {
          if (currMove - 1 >= 0) {
            setCurrMove(currMove - 1);
          }
        }} className='flex flex-row items-center'>
          <TbArrowBadgeLeft />
          <p>Back</p>
        </button>
        <button onClick={() => {
          if (currMove + 1 < moves.length) {
            setCurrMove(currMove + 1);
          }
        }} className='flex flex-row items-center'>
          <p>Next</p>
          <TbArrowBadgeRight />
        </button>
      </div>
      <div>
        <button onClick={() => {
          setCurrMove(0);
        }}>Restart</button>
        <button onClick={() => {
          setCurrMove(moves.length - 1);
        }}>Skip to end</button>
      </div>

    </div>
  );
}
