import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

const Auth = () => {
    const [mode, setMode] = useState("login");

    return (
        <>
            {mode === "login" && <Login setMode={setMode} />}
            {mode === "register" && <Register setMode={setMode} />}
            {mode === "password" && <ForgotPassword setMode={setMode} />}
        </>
    );
};

export default Auth;