import React, { useState, useMemo, useEffect } from "react";
import './Leaderboard.css';
import { GamesInfo } from "../../Constants/Games";
import TextField from '@mui/material/TextField';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../../Firebase/firebaseAuth";
import SearchBar from "../../Components/SearchBar/SearchBar";

const Leaderboard = () => {
    const [user, authLoading, error] = useAuthState(auth);
    const [loading, setLoading] = useState(false);

    const games = Object.values(GamesInfo).map(game => game.title);
    const [selectedGame, setSelectedGame] = useState(games[0]);
    const [searchQuery, setSearchQuery] = useState("");

    const [ranks, setRanks] = useState({});
    const [modelIdToRank, setModelIdToRank] = useState({});
    
    const filteredRanks = useMemo(() => {
        const gameRanks = ranks[selectedGame] || [];
        return gameRanks.filter(rank => 
            rank.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            rank.uid.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [selectedGame, searchQuery, ranks]);

    const handleGameClick = (game) => {
        setSelectedGame(game);
    };

    const getLeaderboardData = async () => {
        // fetch data for each game
        for (const key in GamesInfo) {
            const game = GamesInfo[key];
            const jsonData = { game: key };
            setLoading(true);
            try {
                const token = await user.getIdToken();
                const res = await fetch(process.env.REACT_APP_endpointURL + "/models", {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData)
                });
                const result = await res.json();
                // set ranks array
                setRanks(prevRanks => ({
                    ...prevRanks,
                    [game.title]: result,
                }));
                // set ranks persistent storage
                for (let i = 0; i < result.length; i++) {
                    const item = result[i];
                    setModelIdToRank(prevRanks => ({
                        ...prevRanks,
                        [item.modelId]: i + 1,
                    }));
                }
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getLeaderboardData();
      }, [user]);

    return (
        <div className="leaderboard-page">
            <div className="leaderboard-container">
                <div className="leaderboard-top-bar">
                    <div className="leaderboard-title">
                        Leaderboard
                    </div>
                    <div className="leaderboard-search">
                        <SearchBar setSearchTerm={setSearchQuery} />
                    </div>
                </div>
                <div className="leaderboard-data-container">
                    <div className="games-container">
                        <div className="games-header">
                            <div>Game</div>
                        </div>
                        <div className="games">
                            {games.map((title, index) => (
                                <div
                                    key={index}
                                    className={`game ${title === selectedGame ? 'selectedGame' : ''}`}
                                    onClick={() => handleGameClick(title)}
                                >
                                    {title}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="rankings-container">
                        <div className="rankings-header">
                            <div className="rank-w">Rank</div>
                            <div className="model-w">Model</div>
                            <div className="player-w">Player</div>
                            <div className="rating-w">Rating</div>
                        </div>
                        <div className="rankings">
                            {
                                !loading ?
                                    filteredRanks.map((rank, index) => (
                                        <div key={index} className="rank">
                                            <div className="rank-w rank-txt">{modelIdToRank[rank.modelId]}</div>
                                            <div className="model-w model-txt">{rank.name}</div>
                                            <div className="player-w player-txt">{rank.uid}</div>
                                            <div className="rating-w rating-txt">{rank.rating}</div>
                                        </div>
                                    ))
                                :
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Leaderboard;