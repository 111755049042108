import React, { useState } from "react";
import GameCard from "../../Components/GameCard/GameCard";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { GamesInfo } from "../../Constants/Games";
import "./GameMenu.css";

const GameMenu = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter games based on the search term
  const filteredGames = Object.keys(GamesInfo).filter((game) =>
    game.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col items-center gap-12 px-8 md:px-10">
      {/* Consistent container for the search bar and game cards */}
      <div className="flex flex-col w-full gap-8 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md">
        {/* Pass setSearchTerm to the SearchBar */}

        <SearchBar setSearchTerm={setSearchTerm} />

        {/* Display filtered games */}
        <div className="flex flex-wrap justify-center lg:justify-start gap-8 min-w-full lg:min-w-[768px] w-full ">
          {filteredGames.length > 0 ? (
            filteredGames.map((game, index) => (
              <GameCard key={index} game={game} />
            ))
          ) : (
            <p className="text-lg text-onSurfaceSecond">No games found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameMenu;
