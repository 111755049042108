import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GameMenu from "./Pages/GameMenu/GameMenu";
import Editor from "./Pages/Editor/Editor";
import ProtectedRoute from "./protectedRoute";
import Header from './Components/Header/Header';
import Leaderboard from "./Pages/Leaderboard/Leaderboard";
import GameView from "./Components/GameView/GameView";

const App = () => {
  return (
    <Router>
      <Header />
      <div className="pt-28">
        <Routes>
          {/* FOR TESTING DELTE LATER */}
          <Route path="/test" element={<GameView game={'connect4'} player1={"175c0583-5697-46d9-9669-256f1f1ebbb0"} player2={"175c0583-5697-46d9-9669-256f1f1ebbb0"} ranked={false}/>} />
          <Route path="/" element={<GameMenu />} />
          <Route path="/Leaderboard" element={<Leaderboard />} />
          <Route path="/Editor/:gameName"
            element={
              <ProtectedRoute>
                <Editor />
              </ProtectedRoute>
            }/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
