import React, { useState } from "react";
import './Auth.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { auth } from "../../Firebase/firebaseAuth";
import { sendPasswordResetEmail } from "../../Firebase/firebaseAuth";

const ForgotPassword = ({ setMode }) => {
    const [email, setEmail] = useState("");
    const [errorTxt, setErrorTxt] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    const handlePasswordReset = async (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setErrorTxt("");
                setPasswordReset(true);
                setTimeout(navigateToLogin, 3000);
            })
            .catch((error) => {
                console.error("Error:", error);
                if (error.toString().includes("too-many-requests")) {
                    setErrorTxt("Too many password reset requests.")
                } else if (error.toString().includes("invalid-email")) {
                    setErrorTxt("Invalid email.")
                }
            });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleResetPasswordBtnClick = () => {
        handlePasswordReset(email);
    };

    const navigateToLogin = () => {
        setPasswordReset(false);
        setErrorTxt("");
        setMode("login");
    };

    const resetPasswordEnabled = email !== "";

    return (
        <div className="login-page">
            {
                !passwordReset ?
                <div className="login-container">
                    <div className="login-title">
                        Reset Password
                    </div>
                    <div className="email-input">
                        <TextField
                            required
                            fullWidth
                            label="Email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="error-txt">{errorTxt}</div>
                    <div className="login-btn">
                        <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            disabled={!resetPasswordEnabled}
                            onClick={handleResetPasswordBtnClick}>
                            Reset Password
                        </Button>
                    </div>
                    <div className="under-txt-container">
                        <div
                            className="under-txt"
                            onClick={navigateToLogin}>
                            Back to Login
                        </div>
                    </div>
                </div>
                :
                <div className="reset-confirm-txt">
                    Password reset email has been sent to<br/><strong>{email}</strong>.
                    <br/><br/>
                    Redirecting to login page...
                </div>
            }
        </div>
    );
};

export default ForgotPassword;
