import React, { useState } from "react";
import './Auth.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { auth } from "../../Firebase/firebaseAuth";
import { createUserWithEmailAndPassword } from "../../Firebase/firebaseAuth";

const Register = ({ setMode }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorTxt, setErrorTxt] = useState("");

    const createEmailAccount = async (email, password) => {
        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                setErrorTxt("");
            })
            .catch((error) => {
                console.error("Error:", error);
                if (error.toString().includes("email-already-in-use")) {
                    setErrorTxt("A user with that email already exists.")
                } else if (error.toString().includes("invalid-email")) {
                    setErrorTxt("Invalid email.")
                }
            });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleCreateAccountBtnClick = () => {
        createEmailAccount(email, password);
    };

    const navigateToLogin = () => {
        setErrorTxt("");
        setMode("login");
    };

    const registerEnabled = email !== "" && password !== "";

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-title">
                    Register
                </div>
                <div className="email-input">
                    <TextField
                        required
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="password-input">
                    <TextField
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <div className="error-txt">{errorTxt}</div>
                <div className="login-btn">
                    <Button
                        size="large"
                        fullWidth
                        variant="contained"
                        disabled={!registerEnabled}
                        onClick={handleCreateAccountBtnClick}>
                        Create Account
                    </Button>
                </div>
                <div className="under-txt-container">
                    <div
                        className="under-txt"
                        onClick={navigateToLogin}>
                        Back to Login
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
