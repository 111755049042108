import React, { useEffect } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "./Firebase/firebaseAuth";
import { Alert, Box, CircularProgress } from "@mui/material";
import Auth from "./Pages/Auth/Auth";

const ProtectedRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
  }, [user, loading]);

  if (loading) {
    return (
      <Box display="grid" justifyContent="center" padding={10}>
        <CircularProgress/>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="grid" justifyContent="center" padding={10}>
        <Alert severity="error">An error occured.</Alert>
      </Box>
    )
  }

  if (!user) {
    return (
      <Box>
        <Auth />
      </Box>
    );
  }

  return children;
};

export default ProtectedRoute;
