import {
  Connect4GameCode,
  Connect4StarterCode,
  Connect4Description,
  Connect4ProblemStatement,
  Connect4VisualMap
} from "./Connect4";
import c4Img from "../Images/Game Covers/connect4_cover.png";
import chessImg from "../Images/Game Covers/chess_cover.png";
import othelloImg from "../Images/Game Covers/othello_cover.png";
import goImg from "../Images/Game Covers/go_cover.png";
import checkerImg from "../Images/Game Covers/checker_cover.png";
import fillerImg from "../Images/Game Covers/filler_cover.png";
import tronImg from "../Images/Game Covers/tron_cover.png";

export const GamesInfo = {
  connect4: {
    title: "Connect 4",
    description: "Create horizontal, vertical, or diagonal rows of four.",
    visualMap: Connect4VisualMap,
    active: true,
    image: c4Img,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  chess: {
    title: "Chess",
    description: "Checkmate your opponent in this complex strategy game.",
    visualMap: Connect4VisualMap,
    active: false,
    image: chessImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  othello: {
    title: "Othello",
    description: "Collect the most discs by flipping discs and trapping the opponent's pieces.",
    visualMap: Connect4VisualMap,
    active: false,
    image: othelloImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  go: {
    title: "Go",
    description: "Place stones on a grid to surround more territory than the opponent.",
    visualMap: Connect4VisualMap,
    active: false,
    image: goImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  checkers: {
    title: "Checkers",
    description: "Elimate all opposing pieces by moving diagonally and jumping over opponents' pieces.",
    visualMap: Connect4VisualMap,
    active: false,
    image: checkerImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  filler: {
    title: "Filler",
    description: "Fill the board with your color in this color matching game.",
    visualMap: Connect4VisualMap,
    active: false,
    image: fillerImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  tron: {
    title: "Tron",
    description: "Control cars and avoid crashing into trails and walls.",
    visualMap: Connect4VisualMap,
    active: false,
    image: tronImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
};
