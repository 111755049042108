import * as React from "react";
import { useNavigate } from "react-router-dom";
import { GamesInfo } from "../../Constants/Games";
import { TbPlayerPlayFilled } from "react-icons/tb";
import IconWithTooltip from "../Tooltip/Tooltip";

function GameCard({ game }) {
  let navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/Editor/${game}`)}
      className="p-6 rounded-lg bg-surfaceContain max-w-fit"
    >
      {/* Card Container */}
      <div className="flex flex-col gap-5">
        <img
          src={GamesInfo[game].image}
          alt={game}
          className="rounded-lg w-[240px] h-[240px] border-onSurface sm:w-[180px] sm:h-[180px]"
        />
        {/* Card Content Container */}
        <div className="flex flex-col gap-4">
          <div className="text-xl">{GamesInfo[game].title}</div>
          {/* Card Actions */}
          <div className="flex items-center justify-between w-full">
            {/* Play button */}
            <button className="flex items-center gap-2 px-4 py-2 text-sm rounded-lg w-fit bg-primary">
              <TbPlayerPlayFilled className="size-4 text-onSurface" />
              Play
            </button>
            {/* Tooltip for info */}
            <IconWithTooltip tooltipText={GamesInfo[game].description} />{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameCard;
