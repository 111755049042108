import { useParams } from 'react-router-dom';
import TextEditor from '../../Components/TextEditor/TextEditor';
import { Box, CircularProgress } from '@mui/material';
import CodeTabs from '../../Components/CodeTabs/CodeTabs';

const Editor = () => {
  const { gameName } = useParams();

  if (!gameName) {
    return (
      <Box display="grid" justifyContent="center" padding={10}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <div className="flex p-2 gap-2">
      <CodeTabs game={gameName.toLowerCase()}/>
      <TextEditor game={gameName.toLowerCase()} />
    </div>
  );
};

export default Editor;
