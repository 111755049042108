import React, { useState } from 'react';
import { GamesInfo } from '../../Constants/Games';

const CodeTabs = ( { game } ) => {
  const [activeTab, setActiveTab] = useState('gameSummary');

  return (
    <div className="w-1/2 p-6 rounded-lg bg-surfaceContain">
        
        <h1 className="text-2xl font-bold text-center mb-6">{GamesInfo[game].title}</h1>

        {/* Tab Buttons */}
        <div className="flex border-b">
            <button
            className={`w-1/3 py-2 text-center ${
                activeTab === 'gameSummary' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
            }`}
            onClick={() => setActiveTab('gameSummary')}
            >
            Summary
            </button>
            <button
            className={`w-1/3 py-2 text-center ${
                activeTab === 'sourceCode' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
            }`}
            onClick={() => setActiveTab('sourceCode')}
            >
            Source Code
            </button>
            <button
            className={`w-1/3 py-2 text-center ${
                activeTab === 'battleMode' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
            }`}
            onClick={() => setActiveTab('battleMode')}
            >
            Battle
            </button>
        </div>

        {/* Tab Content */}
        <div className="p-4">
            {activeTab === 'gameSummary' && (
            <div>
                <h2 className="text-lg font-bold">Goal of the game: </h2>
                <p className="text-gray-700">{GamesInfo[game].goal}</p>
                <h2 className="text-lg font-bold">What to implement: </h2>
                <p className="text-gray-700">{GamesInfo[game].problemStatement}</p>
            </div>
            )}
            {activeTab === 'sourceCode' && (
            <div>
                <h2 className="text-lg font-bold">Source Code</h2>
                <p className="text-gray-700">Here is the source code for the game...</p>
            </div>
            )}
            {activeTab === 'battleMode' && (
            <div>
                <h2 className="text-lg font-bold">Battle Mode</h2>
                <p className="text-gray-700">Here is information about battle mode...</p>
            </div>
            )}
        </div>
    </div>
  );
};

export default CodeTabs;
