import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material/";
import React, { useState, useRef } from "react";
import Editor from "@monaco-editor/react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../../Firebase/firebaseAuth";
import { GamesInfo } from '../../Constants/Games';

function TextEditor(props) {
  const { game } = props;

  const [user, auth_loading, error] = useAuthState(auth);

  const initialFiles = {
    name: "player.py",
    defaultLanguage: "python",
    default: GamesInfo[game].starterCode,
  };

  const botNameRef = useRef(null);

  const [botName, setBotName] = useState("");
  const [loading, setLoading] = useState(false);

  const [gameCode, setGameCode] = useState(initialFiles.default);
  const [modelWeights, setModelWeights] = useState("");

  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");

  let fileReader;

  const handleFileRead = (e) => {
    const content = fileReader.result;
    setModelWeights(content);
  };

  const handleFileChosen = (file) => {
    const parts = file.name.split(".");
    setFileName(parts[0]);
    setFileType(parts[1]);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const sendJsonData = async () => {
    const jsonData = {
      rating: 1000,
      game: game,
      name: botName,
      files: modelWeights ? [{
        name: 'player',
        contents: gameCode,
        format: 'py'
      }, {
        name: fileName,
        contents: modelWeights,
        format: fileType
      }] : [{
        name: 'player',
        contents: gameCode,
        format: 'py'
      }],
    };

    console.log(jsonData);
    setLoading(true);

    try {
      const token = await user.getIdToken();

      const res = await fetch(process.env.REACT_APP_endpointURL + "/submit", {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      });

      const result = await res.json();

      console.log(result["percentile"]);
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="grid" justifyContent="center" padding={10}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <div className="editor-container">
       
          <div className="editor-wrapper">
            <Editor
              lineNumbers="off"
              glyphMargin={false}
              folding={false}
              height="60vh"
              width="100vh"
              lineDecorationsWidth={0}
              lineNumbersMinChars={0}
              path={initialFiles.name}
              theme="vs-light"
              onMount={() => {}}
              defaultLanguage={initialFiles.defaultLanguage}
              defaultValue={initialFiles.default}
              onChange={(value) => setGameCode(value)}
              options={{
                minimap: {
                  enabled: false,
                },
                fontSize: 16,
              }}
            />
          </div>

          <div className="flex flex-row justify-between items-center p-2">
            <div>
              <Typography>Upload ML Model Weights</Typography>
              <input
                type="file"
                onChange={(e) => handleFileChosen(e.target.files[0])}
                accept=".h5, .pt, .pth, .pkl, .bin"
              />
            </div>
            <div>
              <TextField
                id="standard-basic"
                variant="standard"
                type="text"
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
                placeholder="Name your bot"
                autoFocus
                inputRef={botNameRef}
              />
              <Button onClick={() => sendJsonData()} disabled={!botName}>
                Submit
              </Button>
            </div>
          </div>
       
      </div>
    </div>
  );
}

export default TextEditor;
