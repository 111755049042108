import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Images/Logos/brainrot.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/firebaseAuth";

function ResponsiveAppBar() {
  const location = useLocation();
  let navigate = useNavigate();

  // Function to check if the current path matches the link path
  const isActive = (path) => location.pathname === path;
  const [user] = useAuthState(auth); // To check the auth state

  return (
    <div className="fixed z-10 w-full px-20 py-4 bg-surface border-b-[1px]">
      <div className="flex items-center justify-between w-full">
        {/* Logo */}
        <Link to="/">
          <img src={Logo} alt="Logo" className="h-12" />
        </Link>

        {/* Menu */}
        <div className="flex items-center gap-12">
          {/* Conditional class based on active route */}
          <Link
            to="/"
            className={
              isActive("/")
                ? "text-onSurface underline underline-offset-4"
                : "text-onSurfaceSecond"
            }
          >
            Games
          </Link>

          <Link
            to="/leaderboard"
            className={
              isActive("/leaderboard")
                ? "text-onSurface underline underline-offset-4"
                : "text-onSurfaceSecond"
            }
          >
            Leaderboard
          </Link>

          <Link
            to="/contest"
            className={
              isActive("/contest")
                ? "text-onSurface underline underline-offset-4"
                : "text-onSurfaceSecond"
            }
          >
            Contest
          </Link>
          {!user ? (
            <button
              onClick={() => navigate("/login")}
              className="flex items-center px-4 py-2 text-sm rounded-lg w-fit bg-primary"
            >
              Login
            </button>
          ) : (
            // TODO: Implement User Profile
            <Link to="/profile">
              <div className="rounded-full size-8 bg-slate-300"></div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResponsiveAppBar;
