import React from "react";
import { TbZoom } from "react-icons/tb";

function SearchBar({ setSearchTerm }) {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="relative w-full">
      {/* Icon */}
      <TbZoom className="absolute text-gray-500 transform -translate-y-1/2 size-5 left-4 top-1/2" />

      {/* Input Field */}
      <input
        className="w-full px-12 py-4 border-2 rounded-lg bg-surfaceContain focus:border-primary focus:outline-none"
        type="text"
        placeholder="Search"
        onChange={handleInputChange}
      />
    </div>
  );
}

export default SearchBar;
